import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  iReoccurringTracks,
  iReoccurringTracksEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import {
  GetReoccurringTracksAction,
  LoadReoccurringTracksAction,
  AddReoccurringTracksAction,
  AddReoccurringTracksSuccessAction,
  UpdateReoccurringTracksAction,
  UpdateReoccurringTracksSuccessAction,
  DeleteReoccurringTracksAction,
  DeleteReoccurringTracksSuccessAction,
  ResetReoccurringTracksStore,
} from '../../actions/reoccurring-tracks.action';

export const ReoccurringTrackAdaptor: EntityAdapter<iReoccurringTracks> =
  createEntityAdapter<iReoccurringTracks>();

const reoccurringTrackReducer = createReducer(
  ReoccurringTrackAdaptor.getInitialState(),
  on(GetReoccurringTracksAction, (state) => ({ ...state })),
  on(LoadReoccurringTracksAction, (state, { reoccurringTracks }) => {
    if (reoccurringTracks == null) return state;
    const rows: iReoccurringTracks[] = [];

    reoccurringTracks.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return ReoccurringTrackAdaptor.addMany(rows, state);
    }

    return ReoccurringTrackAdaptor.upsertMany(rows, state);
  }),
  on(AddReoccurringTracksAction, (state) => ({ ...state })),
  on(
    AddReoccurringTracksSuccessAction,
    (state, { reoccurringTrack: reoccurringTrack }) => {
      const result: any = reoccurringTrack;
      if (reoccurringTrack && result.clientVersion === undefined) {
        return ReoccurringTrackAdaptor.addOne(reoccurringTrack, state);
      }
      return state;
    },
  ),
  on(UpdateReoccurringTracksAction, (state) => ({ ...state })),
  on(
    UpdateReoccurringTracksSuccessAction,
    (state, { reoccurringTrack: reoccurringTrack }) => {
      const result: any = reoccurringTrack;
      if (reoccurringTrack && result.clientVersion === undefined) {
        const updateReoccurringTrack: Update<iReoccurringTracks> = {
          id: reoccurringTrack.id!,
          changes: reoccurringTrack,
        };
        return ReoccurringTrackAdaptor.updateOne(updateReoccurringTrack, state);
      }
      return state;
    },
  ),
  on(DeleteReoccurringTracksAction, (state) => ({ ...state })),
  on(
    DeleteReoccurringTracksSuccessAction,
    (state, { reoccurringTrack: reoccurringTrack }) => {
      const result: any = reoccurringTrack;
      if (reoccurringTrack && result.clientVersion === undefined) {
        const deleteReoccurringTrack: Update<iReoccurringTracks> = {
          id: reoccurringTrack.id!,
          changes: reoccurringTrack,
        };
        return ReoccurringTrackAdaptor.updateOne(deleteReoccurringTrack, state);
      }
      return state;
    },
  ),
  on(ResetReoccurringTracksStore, (state) =>
    ReoccurringTrackAdaptor.removeAll(state),
  ),
);

export function ReoccurringTrackReducer(
  state: iReoccurringTracksEntityState | undefined,
  action: Action,
): iReoccurringTracksEntityState {
  return reoccurringTrackReducer(state, action);
}
